/* eslint-disable */
import PermissionChecker from '@/security/permission-checker';

export class ChefPermissions {
  constructor(currentUser) {
    const permissionChecker = new PermissionChecker(
      currentUser,
    );

    this.read = permissionChecker.match('viewChefs');
    this.edit = permissionChecker.match('editChef');
    this.destroy = permissionChecker.match('deleteChef');
    this.changeStatus = permissionChecker.match('changeChefStatus')
    this.print = permissionChecker.match('printChefsTable');
    this.export = permissionChecker.match('exportChefsTable');
    this.sendNotification = permissionChecker.match('sendNotification');
  }
}